import React from "react";

import { CloseCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

import { Dialog } from "./Dialog";

interface IDialogConfirm {
  open: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
  title?: string;
  description?: string;
}

export const DialogConfirm: React.FC<IDialogConfirm> = ({
  title,
  open,
  handleCloseDialog,
  handleSubmit,
  description,
}) => {
  return (
    <>
      <Dialog
        isOpen={open}
        handleClose={handleCloseDialog}
        className="rec-vmo-modal"
      >
        <>
          <div className="rec-vmo-modal__content center">
            <div className="box-icon">
              <CloseCircleFilled className="icon error" />
            </div>
            <div className="title ">{title ??'Delete'}</div>
            <div className="description">
              <p>{description}</p>
            </div>
          </div>
          <div className="rec-vmo-modal__footer">
            <div className="actions center">
              <Button
                type="primary"
                className="submit"
                danger
                onClick={handleSubmit}
              >
                Delete
              </Button>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};
export default DialogConfirm;
