import React from 'react';

import { Table } from 'antd';
import type { TableProps } from 'antd/es/table';

import { EmptyData } from './EmptyData';

type IPropsTableData = {
  isEmpty?: boolean;
} & TableProps<any>;

export const TableData: React.FC<IPropsTableData> = ({ isEmpty, ...rest }) => {
  return (
    <Table
      {...rest}
      className={`rec-vmo-table ${isEmpty && 'rec-vmo-table--empty'}`}
      locale={{ emptyText: <EmptyData /> }}
    />
  );
};

export default TableData;
