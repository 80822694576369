export interface IQueryParam {
  key: any;
  value: any;
}
export const apiRouteGenerator = (
  route: string,
  idParam?: number,
  query?: IQueryParam[]
) => {
  let url = route;
  if (idParam) {
    url = `${url}/${idParam}`;
  }
  if (query && query.length > 0) {
    const searchParams = new URLSearchParams();
    for (const item of query) {
      if (item.value) {
        searchParams.append(item.key, item.value as string);
      }
    }
    return `${url}?${searchParams.toString()}`;
  }
  return url;
};

export interface IListResponse<T> {
  data: T[];
  error?: any;
  message?: string;
  success: boolean;
  metadata: {
    total_count: number;
    max_page: number;
    page: number;
    per_page: number;
  };
}

export interface IResponse<T> {
  data: T;
  error?: any;
  message?: string;
  success: boolean;
  errorCode?: string;
  statusCode?: number;
}
export interface IListResponseItem<T> {
  data: T[];
  error?: any;
  message?: string;
  success: boolean;
}

export const defaultLimitQuery = [
  {
    key: "limit",
    value: 1000,
  },
];
