import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";
import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import {
  LogoutIcon,
  // PackageIcon,
} from "@/assets";
import { UserLogout } from "@/store/auth/actions";

import { menuSidebar } from "./constants";
import { useCheckDevice } from "@/hooks";
interface IPropsSidebars {
  collapsed: boolean;
  setCollapsed: () => void;
}

export const Sidebars: React.FC<IPropsSidebars> = ({
  collapsed,
  setCollapsed,
}) => {
  const { Sider } = Layout;
  const history = useHistory();
  let resolved = useRouteMatch();
  const pathNameIsThird = history?.location.pathname
    ?.split("/")
    .reverse()
    .slice(1)
    .reverse()
    .join("/");
  const pathName = resolved.path.split("/:");
  const dispatch = useDispatch();

  const [selectedMenu, setSelectedMenu] = useState<number | undefined>();

  const { isMobile } = useCheckDevice();
  useEffect(() => {
    const sidebarFlat = menuSidebar
      ?.map((item) => {
        if (item.items) return item.items;
        return item;
      })
      .flat();
    const pathActive = sidebarFlat.find(
      (itemChild) =>
        itemChild.path === pathName[0] || itemChild.path === pathNameIsThird
    );
    setSelectedMenu(pathActive?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  const handleClickMenuMobile = () => {
    if (isMobile) {
      setCollapsed();
    }
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={`rec-vmo-sidebar ${collapsed ? "active" : ""}`}
      width={240}
    >
      <div className="rec-vmo-sidebar__top">
        <Menu
          mode="inline"
          selectedKeys={[String(selectedMenu)]}
          className="rec-vmo-menu"
        >
          {menuSidebar?.map((item) => {
            if (item.is_group) {
              return (
                <Menu
                  selectedKeys={[String(selectedMenu)]}
                  key={`group_${item.id}`}
                  className="rec-vmo-menu__group rec-vmo-menu"
                >
                  <div
                    key={`${item.id}__group-title`}
                    className="rec-vmo-menu__group__title"
                  >
                    {item.title}
                  </div>
                  {item?.items?.map((menuChild) => {
                    return (
                      <Menu.Item
                        key={menuChild.id}
                        icon={<menuChild.icon />}
                        onClick={handleClickMenuMobile}
                      >
                        <Link to={menuChild.path}>{menuChild.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              );
            }
            const itemIcon = item?.icon ? <item.icon /> : <></>;
            if (item.title === "Purchase bot package" && !collapsed) {
              return (
                <Menu.Item
                  key={item.id}
                  icon={itemIcon}
                  onClick={handleClickMenuMobile}
                >
                  <Tooltip
                    title="Purchase bot package"
                    key={item.id}
                    placement="right"
                  >
                    <Link to={item.path ?? ""}>{item.title}</Link>
                  </Tooltip>
                </Menu.Item>
              );
            }
            return (
              <Menu.Item
                key={item.id}
                icon={itemIcon}
                onClick={handleClickMenuMobile}
              >
                <Link to={item.path ?? ""}>{item.title}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        className="rec-vmo-menu rec-vmo-menu--bottom"
        onClick={() => dispatch(UserLogout())}
      >
        <Menu.Item key="100" icon={<LogoutIcon />}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
