import React from "react";

import { EmptyIcon } from "@/assets";

export const EmptyData = () => {
  return (
    <div className="rec-vmo-box-empty">
      <EmptyIcon />
      <p className="title">No data </p>
      <p className="description">There are no records to display</p>
    </div>
  );
};
