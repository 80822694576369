import {
  ListSuccessIcon,
  // DashboardIcon,
  NotificationMenuIcon,
  MenuJobIcon,
  MenuPostsIcon,
  MenuUsersIcon,
  MenuSettingLocationIcon,
  MenuSettingMyAccountIcon,
  MenuSettingLevelIcon,
  MenuSettingGroupIcon,
  ChatIcon,
  VideoIcon,
  PictureOutlined,
} from "@/assets";

export const menuSidebar = [
  // {
  //   id: 1,
  //   title: "Dashboard",
  //   path: "/",
  //   icon: DashboardIcon,
  // },
  {
    id: 2,
    title: "Jobs",
    path: "/jobs",
    icon: MenuJobIcon,
  },
  {
    id: 3,
    title: "Candidates",
    path: "/candidates",
    icon: ListSuccessIcon,
  },
  {
    id: 4,
    title: "Posts",
    path: "/posts",
    icon: MenuPostsIcon,
  },
  {
    id: 20,
    title: "Videos",
    path: "/videos",
    icon: VideoIcon,
  },
  {
    id: 21,
    title: "Feedback",
    path: "/feedback",
    icon: ChatIcon,
  },
  {
    id: 22,
    title: "Sliders",
    path: "/sliders",
    icon: PictureOutlined,
  },

  {
    id: 6,
    is_group: true,
    title: "Settings",
    items: [
      {
        id: 5,
        title: "Users",
        path: "/settings/users",
        icon: MenuUsersIcon,
      },
      {
        id: 7,
        title: "Teams",
        path: "/settings/teams",
        icon: MenuSettingGroupIcon,
      },
      {
        id: 8,
        title: "Locations",
        path: "/settings/locations",
        icon: MenuSettingLocationIcon,
      },
      {
        id: 9,
        title: "Levels",
        path: "/settings/levels",
        icon: MenuSettingLevelIcon,
      },
    ],
  },
  {
    id: 10,
    is_group: true,
    title: "My account",
    items: [
      {
        id: 12,
        title: "Settings",
        path: "/my-account-settings",
        icon: MenuSettingMyAccountIcon,
      },
      {
        id: 11,
        title: "Notification",
        path: "/my-account/notification",
        icon: NotificationMenuIcon,
      },
    ],
  },
];
