import React from "react";

import { MenuOutlined } from "@ant-design/icons";
import { Layout, Button } from "antd";
import { Link } from "react-router-dom";

import { LogoTextWhite } from "@/assets";
import { useCheckDevice, useToggleValue } from "@/hooks";

import { Notifications } from "./Notifications";
import { Sidebars } from "./Sidebar";
interface IPropsLayoutMain {
  children: React.ReactNode;
}

export const LayoutMain: React.FC<IPropsLayoutMain> = ({ children }) => {
  const { Header, Content } = Layout;
  const { isMobile } = useCheckDevice();
  const collapseStorage = isMobile
    ? "false"
    : window.localStorage.getItem("collapse-menu") ?? "false";
  const [collapsed, setCollapsed] = useToggleValue(JSON.parse(collapseStorage));
  return (
    <Layout>
      <Header className="rec-vmo-header">
        <div className="rec-vmo-header__left">
          <Button
            type="text"
            className="rec-vmo-header__toggle"
            onClick={() => {
              setCollapsed();
              window.localStorage.setItem("collapse-menu", String(!collapsed));
            }}
          >
            <MenuOutlined />
          </Button>
          <Link to="/jobs">
            <img src={LogoTextWhite} alt="" className="rec-vmo-header__logo" />
          </Link>
        </div>
        <div className="rec-vmo-header__right">
          <Notifications />
          <Button
            type="text"
            className="rec-vmo-header__toggle mobile"
            onClick={() => {
              setCollapsed();
            }}
          >
            <MenuOutlined />
          </Button>
        </div>
      </Header>
      <Layout className="rec-vmo-layout">
        <Sidebars collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content className="rec-vmo-layout__content">{children}</Content>
      </Layout>
    </Layout>
  );
};
