import { ApiHelper } from "@/utils";

import { IResponse } from "../common.services";

class UploadGroupService {
  static readonly UPLOAD = "/uploads";
}

export const upload = async (data: FormData) => {
  return ApiHelper.post<IResponse<any>, any>(
    UploadGroupService.UPLOAD,
    data,
    undefined,
    { "content-type": "multipart/form-data" }
  );
};
