import React from "react";

import { Breadcrumb } from "antd";
import upperFirst from "lodash/upperFirst";
import { useLocation } from "react-router-dom";

import { ArrowRightIcon } from "@/assets";

export const Breadcrumbs = () => {
  const location = useLocation();

  const formatBreadcrumb = (bc: string) => bc.split("-").join(" ");

  const pathCreation = (arr: Array<string>) => {
    return arr.map((item, index) => {
      return {
        name: item,
        path: "/".concat(arr.slice(0, index + 1).join("/")),
      };
    });
  };
  const pathArray = pathCreation(location.pathname.split("/"));
  const listItem = pathArray?.map((item) =>
    upperFirst(formatBreadcrumb(item.name))
  );

  if (location.pathname === "/") {
    return (
      <Breadcrumb separator={<ArrowRightIcon />} className="rec-vmo-breadcrumb">
        <Breadcrumb.Item key={1} className="rec-vmo-breadcrumb__link-current">
          Dashboard
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumb separator={<ArrowRightIcon />} className="rec-vmo-breadcrumb">
      {listItem?.map((item, index) => {
        if (item) {
          return index < pathArray.length - 1 ? (
            <Breadcrumb.Item
              key={item}
              className="rec-vmo-breadcrumb__link-old"
            >
              {item}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item
              key={item}
              className="rec-vmo-breadcrumb__link-current"
            >
              {item}
            </Breadcrumb.Item>
          );
        }
        return "";
      })}
    </Breadcrumb>
  );
};
