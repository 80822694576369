import { ApiHelper } from "@/utils";

import { IListResponse, IQueryParam, IResponse } from "../common.services";
import {
  IUser,
  IUserListItem,
  IUserRoleItem,
  IUserStatusItem,
} from "./user.interface";

class UserGroupServiceRoute {
  static readonly USERS = "/users/me";
  static readonly USERS_BASE = "/users";
  static readonly ROLES = "/roles";
  static readonly STATUSES = "/status-user";
}

export const getUserProfile = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IUser>>(
    UserGroupServiceRoute.USERS,
    undefined,
    query
  );
};

export const addUserGroup = async (body: IUser) => {
  return ApiHelper.post<IResponse<IUser>, IUser>(
    UserGroupServiceRoute.USERS,
    body
  );
};

export const updateUserGroup = async (payload: IUser, id?: number) => {
  return ApiHelper.put<IResponse<IUser>, IUser>(
    UserGroupServiceRoute.USERS,
    payload,
    id
  );
};

export const deleteUserGroup = async (id?: number) => {
  return ApiHelper.delete<IResponse<IUser>, number>(
    UserGroupServiceRoute.USERS,
    undefined,
    id
  );
};

export const getUsers = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IUserListItem>>(
    UserGroupServiceRoute.USERS_BASE,
    undefined,
    query
  );
};

export const deleteUsers = async (ids: number[]) => {
  return ApiHelper.delete<IResponse<any>>(UserGroupServiceRoute.USERS_BASE, {
    ids,
  });
};

export const getUserRoles = async () => {
  return ApiHelper.get<IListResponse<IUserRoleItem>>(
    UserGroupServiceRoute.ROLES
  );
};

export const getUserStatuses = async () => {
  return ApiHelper.get<IListResponse<IUserStatusItem>>(
    UserGroupServiceRoute.STATUSES
  );
};
