import { errorCode } from "@/constants";
import queryString from "query-string";

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePassword = (password: string) => {
  return String(password).match(/^(?=.*\d)(?=.*[A-Z]).{8,30}/);
};

export const handleErrorCode = (response: any) => {
  return errorCode[response.errorCode as keyof typeof errorCode];
};

export const modifyDirectionOrder = (value: string | undefined) => {
  if (value === "ascend") return "ASC";
  if (value === "descend") return "DESC";
  return "";
};

export const removeKeyByValue = (object: any, value: any) => {
  for (let key in object) {
    if (object[key] === value) {
      delete object[key];
    }
  }
  return object;
};

export const handleValueFilter = (value: string | undefined) => {
  if (value === "all") return "";
  return value;
};

export const handleStringify = (obj: any, history: any) => {
  const stringifySymbol = queryString.stringify(obj, { skipEmptyString: true });
  history.push({ pathname: window.location.pathname, search: stringifySymbol });
};
