import React from "react";

import BundledEditor from "./BundledEditor";
import { upload } from "@/services/upload";
import { notification } from "antd";

type ExtraConfig = {
  initialValue?: string;
};

type Props = {
  editorRef: any;
  config?: ExtraConfig;
};

export const TinyMCEForm = ({ editorRef, config }: Props) => {
  return (
    <>
      <BundledEditor
        onInit={(evt: any, editor: any) => (editorRef.current = editor)}
        initialValue={config?.initialValue || ""}
        init={{
          disk_cache: true,
          debug: false,
          height: 400,
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars  image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons, fullscreen",
          menubar: false,
          branding: false,
          toolbar:
            "fullscreen undo redo | bold italic underline | fontsize blocks | table insertfile image media link alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  charmap emoticons  ",
          toolbar_mode: "sliding",
          images_upload_handler: async (blobInfo: any) => {
            const fileFormData = new FormData();
            fileFormData.append("file", blobInfo.blob());

            return new Promise((resolve, reject) => {
              // resolve(
              //   "https://dev.amperfii.io/uploads/2/1677579733640-DSC01717_2Mb-FfRsWxPLDn.jpeg"
              // );
              upload(fileFormData)
                .then((data) => {
                  // const { url } = data;
                  resolve(data.data.url);
                })
                .catch((e) => {
                  // showToast({
                  //   type: "error",
                  //   message: ERROR_UPLOADING_IMAGE,
                  // });
                  notification.error({ message: "Cannot upload image" });
                  reject(e);
                });
            });
          },
          quickbars_insert_toolbar: "",
          default_link_target: "_blank",
          fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
          content_style:
            'body { font-family: "Lato" , sans-serif; line-height: 1.2;} img {max-width:100%; height: auto}',
        }}
      />
    </>
  );
};

export default TinyMCEForm;
