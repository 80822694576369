import React from "react";

// import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, InputProps } from "antd";

type IPropsInputNormal = {
  err?: boolean;
} & InputProps;

export const InputNormal: React.FC<IPropsInputNormal> = ({ err, ...rest }) => {
  // const renderSuffix = () => {
  //   if (err === undefined) return <span />;
  //   if (err) {
  //     return <CloseCircleOutlined />;
  //   } else {
  //     return <CheckCircleOutlined />;
  //   }
  // };

  return <Input {...rest} className={`input-normal`} />;
};

export default InputNormal;
