export enum CommonTypes {
  COMMON_REQUEST_ERROR = '@common/COMMON_REQUEST_ERROR',
  COMMON_CLOSE_ERROR = '@common/COMMON_CLOSE_ERROR',
  COMMON_FILTER_SYSTEM_SELECTED = '@common/COMMON_FILTER_SYSTEM_SELECTED',
}

export interface TCommon {
  error: {
    open: boolean;
    message: string;
    action: string;
    timeout?: number | null;
  };
  system_filter?: string;
}
