import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { Switch } from "react-router-dom";

import { RouteWithLayout } from "@/components/RouteWithLayout";
import { requestUserProfile } from "@/store/user/actions";

import "antd/dist/antd.less";
import "./App.scss";
import "./i18n";
import { DialogError } from "./components";
import { routes } from "./routes";

export default function App() {
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    if (jwt && window.location.pathname !== "/sign-in") {
      dispatch(requestUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  return (
    <>
      <Switch>
        {/* <Route exact={true} path="/auth/okta" component={OktaCallback} /> */}
        {routes.map(
          ({ Component, Layout, Protected, routePath, exact, path }, key) => {
            return (
              <RouteWithLayout
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                component={Component}
                exact={exact}
                layout={Layout}
                path={path}
                routePath={routePath}
                protect={Protected}
              />
            );
          }
        )}
      </Switch>
      <DialogError />
    </>
  );
}
