import React from "react";

import { MainLayout, LoginLayout } from "../layouts";

const Login = React.lazy(() => import("@/pages/Auth/Login"));
const ForgotPassword = React.lazy(() => import("@/pages/Auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("@/pages/Auth/ResetPassword"));

const Home = React.lazy(() => import("@/pages/Home"));
const Jobs = React.lazy(() => import("@/pages/Jobs"));
const Posts = React.lazy(() => import("@/pages/Posts"));
const Candidates = React.lazy(() => import("@/pages/Candidates"));
const Notification = React.lazy(() => import("@/pages/MyAccount/Notification"));
const MyAccount = React.lazy(() => import("@/pages/MyAccount/Settings"));
const SettingUsers = React.lazy(() => import("@/pages/Settings/Users"));
const SettingTeams = React.lazy(() => import("@/pages/Settings/Teams"));
const SettingLocations = React.lazy(() => import("@/pages/Settings/Locations"));
const SettingLevels = React.lazy(() => import("@/pages/Settings/Levels"));
const Feedbacks = React.lazy(() => import("@/pages/Feedback"));
const Videos = React.lazy(() => import("@/pages/Videos"));
const Sliders = React.lazy(() => import("@/pages/Sliders"));

export interface IRoute {
  Component: ((props: any) => JSX.Element) | React.FC<any>;
  Layout: ((props: any) => JSX.Element) | React.FC<any>;
  Protected: boolean;
  path?: string | string[];
  routePath?: string;
  from?: string;
  to?: string;
  exact?: boolean;
}

export const routes: IRoute[] = [
  {
    Component: Login,
    Layout: LoginLayout,
    exact: true,
    path: "/sign-in",
    routePath: "/sign-in",
    Protected: false,
  },

  {
    Component: ForgotPassword,
    Layout: LoginLayout,
    exact: true,
    path: "/forgot-password",
    routePath: "/forgot-password",
    Protected: false,
  },
  {
    Component: ResetPassword,
    Layout: LoginLayout,
    exact: true,
    path: "/reset-password",
    routePath: "/reset-password",
    Protected: false,
  },
  {
    Component: Home,
    Layout: MainLayout,
    exact: true,
    path: ["/"],
    routePath: "/",
    Protected: true,
  },
  {
    Component: Jobs,
    Layout: MainLayout,
    exact: true,
    path: ["/jobs"],
    routePath: "/jobs",
    Protected: true,
  },
  {
    Component: Posts,
    Layout: MainLayout,
    exact: true,
    path: ["/posts"],
    routePath: "/posts",
    Protected: true,
  },
  {
    Component: Feedbacks,
    Layout: MainLayout,
    exact: true,
    path: ["/feedback"],
    routePath: "/feedback",
    Protected: true,
  },
  {
    Component: Videos,
    Layout: MainLayout,
    exact: true,
    path: ["/videos"],
    routePath: "/videos",
    Protected: true,
  },
  {
    Component: Candidates,
    Layout: MainLayout,
    exact: true,
    path: ["/candidates"],
    routePath: "/candidates",
    Protected: true,
  },
  {
    Component: SettingUsers,
    Layout: MainLayout,
    exact: true,
    path: ["/settings/users"],
    routePath: "/settings/users",
    Protected: true,
  },
  {
    Component: SettingTeams,
    Layout: MainLayout,
    exact: true,
    path: ["/settings/teams"],
    routePath: "/settings/teams",
    Protected: true,
  },
  {
    Component: SettingLocations,
    Layout: MainLayout,
    exact: true,
    path: ["/settings/locations"],
    routePath: "/settings/locations",
    Protected: true,
  },
  {
    Component: SettingLevels,
    Layout: MainLayout,
    exact: true,
    path: ["/settings/levels"],
    routePath: "/settings/levels",
    Protected: true,
  },

  {
    Component: Notification,
    Layout: MainLayout,
    exact: true,
    path: ["/my-account/notification"],
    routePath: "/my-account/notification",
    Protected: true,
  },
  {
    Component: MyAccount,
    Layout: MainLayout,
    exact: false,
    path: ["/my-account/settings"],
    routePath: "/my-account/settings",
    Protected: true,
  },
  {
    Component: Sliders,
    Layout: MainLayout,
    exact: true,
    path: ["/sliders"],
    routePath: "/sliders",
    Protected: true,
  },
];
