import React from "react";

import { CloseCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { commonCloseError } from "@/store/common/actions";
import { ErrorState } from "@/store/common/selector";

import { Dialog } from "./Dialog";

export const DialogError = () => {
  const dispatch = useDispatch();
  const error = useSelector(ErrorState);

  const handleCloseError = () => {
    dispatch(commonCloseError());
    if (error.action) {
      dispatch({ type: error.action });
    }
  };

  return (
    <>
      <Dialog
        isOpen={error.open}
        handleClose={() => handleCloseError()}
        className="rec-vmo-modal"
      >
        <>
          <div className="rec-vmo-modal__content center">
            <div className="box-icon">
              <CloseCircleFilled className="icon error" />
            </div>
            <div className="title ">{error.message}</div>
          </div>
          <div className="rec-vmo-modal__footer">
            <div className="actions center">
              <Link to={"/sign-in"}>
                <Button
                  type="primary"
                  className="submit"
                  onClick={() => handleCloseError()}
                >
                  Got It
                </Button>
              </Link>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};
export default DialogError;
