import React, { useEffect } from "react";

import { Button, Popover, Tooltip } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import { NotificationIcon } from "@/assets";
import { useToggleValue } from "@/hooks";

export const Notifications = () => {
  // const dispatch = useDispatch();
  // const notifications = useSelector(ListNotification);
  // const totalUnread = useSelector(ListNotificationUnread);
  const [openPopover, toggleOpenPopover] = useToggleValue(false);

  const handleMarkReadAll = async () => {};

  const ContentNotifications = () => (
    <div className="rec-vmo-notification__detail">
      <div className="top">
        <p className="title">Notification {"6"}</p>
        <Tooltip title="Mark all as read">
          <Button
            type="text"
            className={`is-un-read`}
            onClick={handleMarkReadAll}
          >
            {/* <ChecksIcon /> */}
            Mark as read
          </Button>
        </Tooltip>
      </div>
      <div className="content">
        <Link to={""}>
          <div className={`item un-read}`}>
            <p className="title">Content Notification</p>
            <p className="time">{moment().format("DD/MM/YYYY HH:mm:ss")}</p>
          </div>
        </Link>
        <div className="view-all">
          <Link to="/my-account/notification">View all notification</Link>
        </div>
        {/* {!notifications?.data?.length && <EmptyData />} */}
      </div>
    </div>
  );

  useEffect(() => {
    const concernedElement: any = document.querySelector(
      ".rec-vmo-notification__list"
    );
    const handleClick = (event: any) => {
      if (concernedElement && !concernedElement.contains(event.target)) {
        if (openPopover) {
          toggleOpenPopover();
        }
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  return (
    <div className="rec-vmo-notification">
      <Popover
        placement="bottomRight"
        title={""}
        content={ContentNotifications}
        trigger="click"
        overlayClassName="rec-vmo-notification__list"
        open={openPopover}
      >
        <Button
          type="text"
          className="rec-vmo-notification__icon"
          onClick={toggleOpenPopover}
        >
          <span className="is-new-notification"></span>
          <NotificationIcon />
        </Button>
      </Popover>
    </div>
  );
};
