import React from 'react';

import { BackgroundAuthenticate } from '@/assets';
export const LoginLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="authenticate">
      <img src={BackgroundAuthenticate} alt="" className="authenticate__background" />
      <div className="authenticate__page">{children}</div>
    </div>
  );
};
